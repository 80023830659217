<template>
  <div>
    <StatusFilterList
      v-bind="params"
      v-model="params.status"
      @change="change"
      :list="list"
    >
      <div class="text-right" slot="opt">
        <a-space :size="20">
          <gf-re-input-search
            @search="change({ name: $event, current: 1 })"
            style="width: 240px"
          >
          </gf-re-input-search>
        </a-space>
      </div>
      <ActivityCard
        @click.native="open(dataSource)"
        slot="card"
        slot-scope="{ dataSource }"
        :dataSource="dataSource"
        :role="5"
      >
      </ActivityCard>
    </StatusFilterList>

    <a-drawer :width="800" :visible="visible" @close="onClose">
      <div class="p-20 bg-f9">
        <div class="relative flex">
          <div
            style="width: 400px; height: 225px"
            class="relative border border-eee rounded mr-20"
          >
            <img
              v-if="detail.Cover"
              :src="detail.Cover.url"
              class="w-full h-full object-contain"
              alt=""
            />
            <div
              class="
                absolute
                top-20
                bg-primary
                rounded-tr-full rounded-br-full
                text-xs text-white text-center
                px-10
              "
              :class="{
                'status-1': detail.status === 0,
                'status-2': detail.status === 1,
                'status-0': [2, 10].includes(detail.status),
              }"
              style="height: 26px; line-height: 26px"
            >
              <span v-if="detail.status === 0">报名中</span>
              <span v-else-if="detail.status === 1">进行中</span>
              <span v-else-if="detail.status === 2">已结束</span>
              <span v-else-if="detail.status === 10">已关闭</span>
            </div>
            <div
              class="
                px-10
                absolute
                right-0
                bottom-0
                text-white text-center text-xs
              "
              style="
                height: 26px;
                line-height: 26px;
                background-color: rgba(0, 0, 0, 0.5);
              "
            >
              {{ detail.type.Name }}
            </div>
          </div>
          <div style="width: calc(100% - 400px)" class="pb-4 relative">
            <div class="font-bold mb-10">{{ detail.Name }}</div>
            <div class="mb-10 text-xs text-999 flex items-center">
              <a-icon type="clock-circle" class="mr-10" />
              {{ detail.time[0] | formatterTime("YYYY-MM-DD HH:mm") }} 至
              {{ detail.time[1] | formatterTime("YYYY-MM-DD HH:mm") }}
            </div>
            <div class="text-xs text-999 flex items-center mb-20">
              <a-icon type="environment" class="mr-10" />
              <span>{{ detail.address }}</span>
            </div>
            <div class="mb-40 flex justify-between items-center w-full text-xs">
              <div class="flex items-center">
                <a-progress
                  style="width: 120px"
                  class="mr-10"
                  :show-info="false"
                  :percent="
                    detail.participants / detail.Quota < 1
                      ? Math.ceil((detail.participants / detail.Quota) * 100)
                      : Math.floor((detail.participants / detail.Quota) * 100)
                  "
                />
                <div>
                  名额已占{{
                    detail.participants / detail.Quota < 1
                      ? Math.ceil((detail.participants / detail.Quota) * 100)
                      : Math.floor((detail.participants / detail.Quota) * 100)
                  }}%
                </div>
              </div>
            </div>
            <div class="text-primary absolute bottom-0 right-0">
              共{{ detail.participants }}/{{ detail.Quota }}人报名
            </div>
          </div>
        </div>
      </div>

      <div class="text-lg font-bold mt-40 mb-20">
        <span class="mr-10 text-primary align-middle">|</span>
        <span class="align-middle text-111 font-bold">活动描述</span>
      </div>

      <a-space class="w-full" :size="20" direction="vertical">
        <div
          v-for="(item, index) in detail.Desc.split('\n')"
          :key="index"
          style="word-wrap: break-word"
        >
          {{ item }}
        </div>
      </a-space>

      <div class="text-lg font-bold mt-40 mb-20">
        <span class="mr-10 text-primary align-middle">|</span>
        <span class="align-middle text-111 font-bold">活动发起人</span>
      </div>

      <a-space class="w-full" :size="20" direction="vertical">
        <div
          v-for="(item, index) in detail.Initiators"
          :key="index"
          style="word-wrap: break-word"
          class="flex items-center"
        >
          <div
            class="
              mr-10
              w-40
              h-40
              rounded-full
              overflow-hidden
              border border-eee
            "
          >
            <img
              class="w-full h-full object-contain"
              :src="item.Logo.url"
              alt=""
            />
          </div>
          <div>{{ item.Name }}</div>
        </div>
      </a-space>

      <div class="text-lg font-bold mt-40 mb-20">
        <span class="mr-10 text-primary align-middle">|</span>
        <span class="align-middle text-111 font-bold">活动时间</span>
      </div>

      <div>
        <span
          >{{ detail.time[0] | formatterTime("YYYY-MM-DD HH:mm") }} 至
          {{ detail.time[1] | formatterTime("YYYY-MM-DD HH:mm") }}</span
        >
      </div>

      <div class="text-center mt-40" v-if="detail.status === 0">
        <a-button type="primary" size="large" @click="doSignout"
          >取消报名</a-button
        >
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { getMyActivityList, signout } from "@/api/api/activity";

export default {
  data() {
    return {
      params: {
        current: 1,
        limit: 5,
        total: 0,
        status: 0,
        name: "",
        filters: [
          { value: 1, name: "报名中" },
          { value: 2, name: "进行中" },
          { value: 3, name: "已结束" },
        ],
      },
      list: [],
      visible: false,
      detail: {
        time: [],
        type: {},
        Desc: "",
      },
    };
  },
  computed: {
    role() {
      return this.$store.state.userInfo.role;
    },
  },
  created() {
    this.change();
  },
  methods: {
    signout(id) {
      signout(id)
        .then(() => {
          this.$message.success("取消报名成功");
          this.visible = false;
          this.change();
        })
        .catch(() => {
          this.$message.error("取消报名失败");
        });
    },
    doSignout() {
      const join = this.signout,
        id = this.detail.id,
        name = this.detail.Name;
      this.$confirm({
        title: "取消报名",
        content: `您将取消《${name}》活动的报名`,
        okText: "取消报名",
        cancelText: "关闭",
        okType: "primary",
        onOk: () => {
          join(id);
        },
      });
    },
    onClose() {
      this.visible = false;
    },
    open(item) {
      this.detail = item;
      this.visible = true;
    },
    change(e) {
      const params = this.generateParams(e);
      this.getList(params);
    },
    generateParams(e) {
      Object.assign(this.params, e);

      const { name, status, limit, current } = this.params;

      const temp = {
        status: status ? status - 1 : undefined,
        limit,
        offset: limit * (current - 1),
        name,
      };

      return temp;
    },
    getList(params) {
      getMyActivityList(params)
        .then(({ count, rows }) => {
          this.list = rows;
          this.$set(this.params, "total", count);
          let current = this.params.current;
          if (rows.length === 0 && current !== 1) {
            console.log(current, "当前页面没有数据了");
          }
        })
        .catch(() => {
          this.$message.error("获取列表失败");
        });
    },
  },
};
</script>