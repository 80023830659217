import {
    transformOptions,
    transformAttachment,
    getTime
} from './_util'
import moment from 'moment';

export function transformActivity(data) {
    const detail = data.Detail || {};
    const Initiators = detail.Initiators || []
    const type = transformOptions(detail.Type, 'ActivityType') || {};
    const temp = {
        address: detail.Address,
        Desc: detail.Desc,
        Name: detail.Name,
        Quota: detail.Quota,
        time: [moment(detail.StartedAt), moment(detail.EndedAt)],
        Initiators: Initiators.map((item) => {
            return {
                Name: item.Name,
                Logo: transformAttachment(item.Logo)
            }
        }),
        Cover: transformAttachment(detail.Cover),
        categoryId: type.ID,
        type,
        id: data.ID,
        status: data.Status,
        result: data.Result,
        participants: data.Participants,
        visible: data.Visible,
        candidates: data.Candidates,
        createdAt: getTime(data.CreatedAt)
    }
    return temp;
}