// 沙龙活动
import request from '@/utils/request1'
import { transformActivity } from '@/utils/transform/activity'

// 获取沙龙列表
export function getActivityList(params) {
    return request.get('/activity', { params }).then(({ data = {} }) => {
        const rows = data.rows || []
        const temp = {
            count: data.count,
            rows: rows.map(transformActivity)
        }
        return Promise.resolve(temp)
    })
}
// 获取公开活动详情
export function getPublichActivity(id) {
    return request.get(`/activity/${id}`).then(({ data = {} }) => {
        return Promise.resolve(transformActivity(data))
    })
}
// 获取沙龙日历
export function getActivityCalendar(params) {
    return request.get('/activity/_/calendar', { params })
}
// 获取后台活动列表
export function getOpActivityList(params) {
    return request.get('/op/activity', { params }).then(({ data = {} }) => {
        const rows = data.rows || []
        const temp = {
            count: data.count,
            rows: rows.map(transformActivity)
        }
        return Promise.resolve(temp)
    })
}
// 获取我的活动列表
export function getMyActivityList(params) {
    return request.get('/user/activity', { params }).then(({ data = {} }) => {
        const rows = data.rows || []
        const temp = {
            count: data.count,
            rows: rows.map(transformActivity)
        }
        return Promise.resolve(temp)
    })
}
// 创建活动
export function createActivity(data) {
    return request.post('/op/activity', data)
}
// 删除活动
export function delActivity(id) {
    return request.delete(`/op/activity/${id}`)
}
// 获取活动
export function getActivity(id) {
    return request.get(`/op/activity/${id}`)
}
// 更新活动
export function updateActivity(id, data) {
    return request.put(`/op/activity/${id}`, data)
}
// 关闭活动
export function closeActivity(id, data) {
    return request.post(`/op/activity/${id}/close`, data)
}
// 设置活动可见性
export function updateActivityVisible(id) {
    return request.post(`/op/activity/${id}/visible`)
}
// 获取活动候补名单
export function getActivityCandidate(id, params) {
    return request.get(`/op/activity/${id}/candidates`, { params })
}
// 获取活动报名名单
export function getActivityParticipant(id, params) {
    return request.get(`/op/activity/${id}/participants`, { params })
}

// 报名
export function signup(id, data) {
    return request.post(`/activity/${id}/signup`, data)
}

// 取消报名
export function signout(id) {
    return request.post(`/activity/${id}/signout`)
}

export function getActivity1(id) {
    return request.get(`/activity/${id}/participants/0`)
}